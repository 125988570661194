import Vue from "vue";
import App from "./App.vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "@/assets/styles.scss";
import router from "./router";
import VmBackTop from "vue-multiple-back-top";
let VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);

Vue.component(VmBackTop.name, VmBackTop);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
