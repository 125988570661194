<template>
  <div
    class="flex flex-wrap lg:justify-between items-center mt-16 lg:mt-20 pt-16 lg:pt-20 pb-16 lg:pb-0 container-contents"
  >
    <div
      class="container mx-auhref flex md:flex-row flex-col justify-between items-center"
    >
      <div class="order-2 lg:order-1 text-blue">
        <h5
          class="text-center text-normal font-bold lg:font-semibold text-2xl leading-33 mb-4 lg:text-left"
        >
          Behold
        </h5>
        <p
          class="w-full lg:w-4/5 font-medium text-center lg:text-left text-base lg:text-xl tracking-widest1 leading-7 lg:leading-9 mb-8"
        >
          A daily guided prayer app that helps Christians center their lives and
          minds on God.
        </p>
        <div class="flex justify-center lg:justify-start items-center mt-8">
          <div class="w-full relative text-center lg:text-left">
            <a
              href="/behold"
              class="behold-link-border outline-none focus:outline-none text-lg font-bold leading-25 tracking-widest1 text-blue"
            >
              View the Case Study
            </a>
          </div>
        </div>
      </div>
      <a
        href="/behold"
        class="w-full lg:w-1/2 order-1 lg:order-2 outline-none focus:outline-none"
      >
        <img
          class="object-cover object-center"
          src="../assets/images/behold.png"
          alt="Screenshot of behold app"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Behold",
};
</script>

<style lang="scss" scoped>
.behold-link-border {
  position: relative;
  text-decoration: none;

  &::before {
    background: #211a4c;
    bottom: -4px;
    left: 0;
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
  }
  &:hover {
    &::before {
      animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
}

.behold-link-border:hover:before {
  animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
