<template>
  <footer class="container-contents pt-20 pb-16 lg:pb-20 lg:pt-120">
    <div class="mx-auto flex flex-col lg:flex-row">
      <Logo class="flex justify-center lg:justify-start" />
      <div class="flex mt-16 lg:mt-0 flex justify-center lg:justify-end">
        <a
          href="https://drive.google.com/file/d/1rtFla6HYYecrwvXsFqgdXbHrvFb1B-Ni/view"
          target="_blank"
          class="transform transition duration-500 hover:scale-125"
        >
          <svg
            width="20"
            height="26"
            viewBox="0 0 20 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.9969 5.85761C18.9953 5.71342 18.9396 5.57214 18.8336 5.46611L14.5321 1.16458C14.5319 1.16439 14.5318 1.16434 14.5316 1.1642C14.43 1.06272 14.2897 1 14.1347 1H2.18498C1.53262 1 1.00195 1.53062 1.00195 2.18298V23.817C1.00195 24.4693 1.53258 25 2.18494 25H17.8151C18.4674 25 18.9981 24.4693 18.9981 23.817V5.86342C18.9981 5.86136 18.9971 5.85962 18.9969 5.85761ZM14.6967 2.91864L17.0795 5.30148H14.7558C14.7232 5.30148 14.6967 5.27491 14.6967 5.24233V2.91864ZM17.8743 23.817C17.8743 23.8496 17.8477 23.8761 17.8152 23.8761H2.18498C2.15241 23.8761 2.12587 23.8496 2.12587 23.817V2.18298C2.12587 2.15041 2.15241 2.12388 2.18498 2.12388H13.5728V5.24233C13.5728 5.89464 14.1035 6.42541 14.7559 6.42541H17.8743V23.817Z"
              fill="#191919"
              stroke="#191919"
              stroke-width="2"
            />
            <path
              d="M14.973 8.68823H5.02727C4.71696 8.68823 4.46533 8.93981 4.46533 9.25017C4.46533 9.56048 4.71696 9.81211 5.02727 9.81211H14.973C15.2833 9.81211 15.5349 9.56048 15.5349 9.25017C15.5349 8.93981 15.2833 8.68823 14.973 8.68823Z"
              fill="#191919"
              stroke="#191919"
              stroke-width="0.5"
            />
            <path
              d="M14.973 12.0791H5.02727C4.71696 12.0791 4.46533 12.3307 4.46533 12.641C4.46533 12.9514 4.71696 13.203 5.02727 13.203H14.973C15.2833 13.203 15.5349 12.9514 15.5349 12.641C15.5349 12.3307 15.2833 12.0791 14.973 12.0791Z"
              fill="#191919"
              stroke="#191919"
              stroke-width="0.5"
            />
            <path
              d="M14.973 15.47H5.02727C4.71696 15.47 4.46533 15.7216 4.46533 16.0319C4.46533 16.3423 4.71696 16.5938 5.02727 16.5938H14.973C15.2833 16.5938 15.5349 16.3423 15.5349 16.0319C15.5349 15.7216 15.2833 15.47 14.973 15.47Z"
              fill="#191919"
              stroke="#191919"
              stroke-width="0.5"
            />
            <path
              d="M10.0001 18.8608H5.02727C4.71696 18.8608 4.46533 19.1125 4.46533 19.4228C4.46533 19.7331 4.71696 19.9847 5.02727 19.9847H10.0001C10.3105 19.9847 10.5621 19.7331 10.5621 19.4228C10.5621 19.1124 10.3105 18.8608 10.0001 18.8608Z"
              fill="#191919"
              stroke="#191919"
              stroke-width="0.5"
            />
          </svg>
        </a>

        <a
          href="https://www.linkedin.com/in/folarin-lawal/"
          target="_blank"
          class="mx-12 transform transition duration-500 hover:scale-125 mx-12"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.4344 2.73381C5.4344 4.15115 4.388 5.29915 2.66613 5.29915C1.0464 5.29915 0 4.15141 0 2.73381C0 1.28235 1.08027 0.168213 2.73387 0.168213C4.388 0.168213 5.40107 1.28235 5.4344 2.73381ZM0.134933 23.8314V7.32475H5.26613V23.8317H0.134933V23.8314Z"
              fill="#191919"
            />
            <path
              d="M8.33655 12.5907C8.33655 10.5318 8.26935 8.81046 8.20215 7.32486H12.6573L12.8944 9.62033H12.9955C13.6704 8.53953 15.3245 6.95312 18.0928 6.95312C21.4683 6.95312 24 9.21499 24 14.0758V23.8318H18.8688V14.6835C18.8688 12.5566 18.1261 11.1057 16.2693 11.1057C14.8517 11.1057 14.0083 12.0843 13.6371 13.0297C13.5016 13.367 13.4677 13.8398 13.4677 14.3126V23.8318H8.33655V12.5907Z"
              fill="#191919"
            />
          </svg>
        </a>

        <a
          href="mailto:lawal.folarin@gmail.com"
          class="transform transition duration-500 hover:scale-125"
        >
          <svg
            width="32"
            height="24"
            viewBox="0 0 32 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.1369 0.0776367H3.78937C1.69993 0.0776367 0 1.68358 0 3.6575V20.352C0 22.326 1.69993 23.9319 3.78937 23.9319H28.1369C30.2264 23.9319 31.9262 22.326 31.9262 20.352V3.6575C31.9262 1.68358 30.2264 0.0776367 28.1369 0.0776367ZM28.1369 20.7715H3.78937C3.48223 20.7715 3.21287 20.5755 3.21287 20.352V5.86509L14.2246 15.0106C14.4504 15.1981 14.7375 15.3014 15.0331 15.3014L16.8928 15.3014C17.1883 15.3014 17.4755 15.1981 17.7013 15.0106L28.7136 5.86476V20.3522C28.7136 20.5755 28.444 20.7715 28.1369 20.7715ZM15.9632 12.1633L5.25285 3.23808H26.6735L15.9632 12.1633Z"
              fill="#191919"
            />
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "Footer",
  components: { Logo },
};
</script>
