<template>
  <section
    class="flex flex-wrap lg:justify-between items-center pt-16 container-contents lg:pt-20 pb-16"
  >
    <div class="container mx-auto flex md:flex-row flex-col items-center">
      <a
        href="/oasis"
        class="w-full lg:w-1/2 lg:mb-10 outline-none focus:outline-none"
      >
        <img
          class="object-contain object-center"
          src="../assets/images/oasis.png"
          alt="Screenshot of behold app"
        />
      </a>
      <div
        class="w-full lg:w-1/2 flex justify-end items-center text-center text-red"
      >
        <div class="w-full lg:w-4/5">
          <h5
            class="text-center text-normal font-bold lg:font-semibold text-2xl leading-33 mb-4 lg:text-left mt-12"
          >
            Oasis Smart Thermostat
          </h5>
          <p
            class="w-full font-medium text-center lg:text-left text-base lg:text-xl tracking-widest1 leading-7 lg:leading-9 mb-8"
          >
            An automated smart home service provider which allows its users to
            remotely change the temperature in their homes using smart devices
            anytime and from anywhere.
          </p>

          <div class="flex justify-center lg:justify-start items-center mt-8">
            <div class="w-full text-center lg:text-left relative">
              <a
                href="/oasis"
                class="oasis-link-border outline-none focus:outline-none text-lg font-bold leading-25 tracking-widest1 text-red"
              >
                View the Case Study
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Oasis",
};
</script>

<style lang="scss" scoped>
.oasis-link-border {
  position: relative;
  text-decoration: none;

  &::before {
    background: #8f4f4f;
    bottom: -4px;
    left: 0;
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
  }
  &:hover {
    &::before {
      animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
  &:hover:before {
    animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}
@keyframes line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
