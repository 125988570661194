<template>
  <div>
    <main
      class="container-contents lg:h-screen pt-16 text-black-dark"
      id="section1"
    >
      <section class="h-full lg:flex lg:flex-wrap">
        <div>
          <Logo class="flex justify-start" />
        </div>

        <div class="relative w-full mt-24 lg:mt-0">
          <div class="flex justify-between relative">
            <article>
              <h2
                class="text-2xl lg:text-40 leading-33 lg:leading-55 mb-8 lg:mb-12 font-semibold"
              >
                Hi, I’m Folarin
              </h2>

              <div>
                <p class="text-lg leading-8 lg:leading-11 lg:text-32">
                  I design products which leverage research, design and
                  <br class="hidden lg:flex" />
                  technology to provide seamless experiences to users and
                  <br class="hidden lg:flex" />
                  help businesses achieve their goals.
                </p>

                <div class="flex mt-16 mb-16 lg:mb-0 lg:mt-20">
                  <a
                    href="https://drive.google.com/file/d/1rtFla6HYYecrwvXsFqgdXbHrvFb1B-Ni/view"
                    target="_blank"
                    class="transform transition duration-500 hover:scale-125"
                  >
                    <svg
                      class="inline-flex w-17 h-22 lg:w-25 lg:h-33"
                      viewBox="0 0 25 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.746 7.32201C23.7441 7.14178 23.6743 6.96518 23.5419 6.83264L18.165 1.45572C18.1648 1.45549 18.1646 1.45543 18.1644 1.45525C18.0374 1.3284 17.8619 1.25 17.6683 1.25H2.73111C1.91566 1.25 1.25232 1.91328 1.25232 2.72873V29.7712C1.25232 30.5866 1.9156 31.2499 2.73105 31.2499H22.2688C23.0842 31.2499 23.7475 30.5867 23.7475 29.7712V7.32928C23.7475 7.3267 23.7463 7.32453 23.746 7.32201ZM18.3707 3.6483L21.3493 6.62686H18.4447C18.4039 6.62686 18.3707 6.59363 18.3707 6.55291V3.6483ZM22.3428 29.7713C22.3428 29.812 22.3096 29.8452 22.2688 29.8452H2.73111C2.69039 29.8452 2.65722 29.812 2.65722 29.7713V2.72873C2.65722 2.68801 2.69039 2.65484 2.73111 2.65484H16.9659V6.55291C16.9659 7.3683 17.6293 8.03176 18.4447 8.03176H22.3428V29.7713Z"
                        fill="#191919"
                        stroke="#191919"
                        stroke-width="2"
                      />
                      <path
                        d="M18.716 10.8602H6.28384C5.89595 10.8602 5.58142 11.1747 5.58142 11.5626C5.58142 11.9505 5.89595 12.265 6.28384 12.265H18.716C19.1039 12.265 19.4184 11.9505 19.4184 11.5626C19.4184 11.1747 19.1039 10.8602 18.716 10.8602Z"
                        fill="#191919"
                        stroke="#191919"
                        stroke-width="0.5"
                      />
                      <path
                        d="M18.716 15.0988H6.28384C5.89595 15.0988 5.58142 15.4133 5.58142 15.8012C5.58142 16.1891 5.89595 16.5037 6.28384 16.5037H18.716C19.1039 16.5037 19.4184 16.1891 19.4184 15.8012C19.4184 15.4133 19.1039 15.0988 18.716 15.0988Z"
                        fill="#191919"
                        stroke="#191919"
                        stroke-width="0.5"
                      />
                      <path
                        d="M18.716 19.3374H6.28384C5.89595 19.3374 5.58142 19.6519 5.58142 20.0398C5.58142 20.4277 5.89595 20.7422 6.28384 20.7422H18.716C19.1039 20.7422 19.4184 20.4277 19.4184 20.0398C19.4184 19.652 19.1039 19.3374 18.716 19.3374Z"
                        fill="#191919"
                        stroke="#191919"
                        stroke-width="0.5"
                      />
                      <path
                        d="M12.4999 23.576H6.28384C5.89595 23.576 5.58142 23.8906 5.58142 24.2785C5.58142 24.6664 5.89595 24.9809 6.28384 24.9809H12.4999C12.8878 24.9809 13.2024 24.6664 13.2024 24.2785C13.2024 23.8905 12.8878 23.576 12.4999 23.576Z"
                        fill="#191919"
                        stroke="#191919"
                        stroke-width="0.5"
                      />
                    </svg>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/folarin-lawal/"
                    target="_blank"
                    class="mx-12 transform transition duration-500 hover:scale-125"
                  >
                    <svg
                      class="w-6 h-6 lg:w-8 lg:h-8"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.24587 3.64517C7.24587 5.53494 5.85067 7.06561 3.55484 7.06561C1.3952 7.06561 0 5.5353 0 3.64517C0 1.70988 1.44036 0.224365 3.64516 0.224365C5.85067 0.224365 7.20142 1.70988 7.24587 3.64517ZM0.179911 31.7753V9.76641H7.02151V31.7757H0.179911V31.7753Z"
                        fill="#191919"
                      />
                      <path
                        d="M11.1154 16.7876C11.1154 14.0423 11.0258 11.7472 10.9362 9.7664H16.8764L17.1925 12.827H17.3273C18.2272 11.386 20.4327 9.27075 24.1237 9.27075C28.6243 9.27075 32 12.2866 32 18.7676V31.7756H25.1584V19.578C25.1584 16.742 24.1682 14.8075 21.6924 14.8075C19.8023 14.8075 18.6777 16.1124 18.1827 17.3728C18.0021 17.8226 17.957 18.453 17.957 19.0834V31.7756H11.1154V16.7876Z"
                        fill="#191919"
                      />
                    </svg>
                  </a>

                  <a
                    href="mailto:lawal.folarin@gmail.com"
                    class="transform transition duration-500 hover:scale-125"
                  >
                    <svg
                      class="w-8 h-6 lg:w-43 lg:h-8"
                      viewBox="0 0 43 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M37.5159 0.103516H5.05249C2.26657 0.103516 0 2.24478 0 4.87667V27.1361C0 29.768 2.26657 31.9092 5.05249 31.9092H37.5159C40.3018 31.9092 42.5683 29.768 42.5683 27.1361V4.87667C42.5683 2.24478 40.3018 0.103516 37.5159 0.103516ZM37.5159 27.6953H5.05249C4.64298 27.6953 4.28382 27.434 4.28382 27.1361V7.82012L18.9662 20.0141C19.2672 20.2642 19.65 20.4018 20.0441 20.4018L22.5238 20.4019C22.9177 20.4019 23.3007 20.2642 23.6018 20.0141L38.2848 7.81968V27.1363C38.2848 27.434 37.9253 27.6953 37.5159 27.6953ZM21.2842 16.2177L7.00379 4.31743H35.5646L21.2842 16.2177Z"
                        fill="#191919"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </article>

            <div class="fixed mt-8 lg:mt-20 right-0 mr-4 lg:mr-20">
              <svg
                @click="active = 'home'"
                :class="{
                  active: active === 'home',
                  inactive: active !== 'home',
                }"
                class="cursor-pointer"
                v-scroll-to="{
                  el: '#section1',
                  duration: 500,
                  easing: 'linear',
                }"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10"
                  stroke="#191919"
                  stroke-width="2"
                />
                <circle cx="11" cy="11" r="6" fill="#191919" />
              </svg>

              <span class="h-32 text-transparent">s</span>

              <svg
                @click="active = 'timestudy'"
                :class="{
                  active: active === 'timestudy',
                  inactive: active !== 'timestudy',
                }"
                class="cursor-pointer"
                v-scroll-to="{
                  el: '#section5',
                  duration: 500,
                  easing: 'linear',
                }"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10"
                  stroke="#440A67"
                  stroke-width="2"
                />
                <circle cx="11" cy="11" r="6" fill="#440A67" />
              </svg>
              <span class="h-32 text-transparent">s</span>

              <svg
                @click="active = 'plexus'"
                :class="{
                  active: active === 'plexus',
                  inactive: active !== 'plexus',
                }"
                class="cursor-pointer"
                v-scroll-to="{
                  el: '#section4',
                  duration: 500,
                  easing: 'ease-in',
                }"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10"
                  stroke="#025955"
                  stroke-width="2"
                />
                <circle cx="11" cy="11" r="6" fill="#025955" />
              </svg>
              <span class="h-32 text-transparent">s</span>

              <svg
                @click="active = 'behold'"
                :class="{
                  active: active === 'behold',
                  inactive: active !== 'behold',
                }"
                class="cursor-pointer"
                v-scroll-to="{
                  el: '#section2',
                  duration: 500,
                  easing: 'ease-in',
                }"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10"
                  stroke="#211A4C"
                  stroke-width="2"
                />
                <circle cx="11" cy="11" r="6" fill="#211A4C" />
              </svg>
              <!--              </a>-->
              <span class="h-32 text-transparent">s</span>

              <svg
                @click="active = 'oasis'"
                :class="{
                  active: active === 'oasis',
                  inactive: active !== 'oasis',
                }"
                class="cursor-pointer"
                v-scroll-to="{
                  el: '#section3',
                  duration: 500,
                  easing: 'linear',
                }"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="10"
                  stroke="#8F4F4F"
                  stroke-width="2"
                />
                <circle cx="11" cy="11" r="6" fill="#8F4F4F" />
              </svg>
            </div>
          </div>
        </div>
      </section>
    </main>

    <div class="container-contents pt-20 lg:pt-20">
      <div class="w-full relative text-left projects-box">
        <a href="/" class="projects-link-border">
          <span class="font-bold text-2xl lg:text-32 tracking-widest1">
            Projects
          </span>
        </a>
      </div>
    </div>

    <div class="h-16 lg-h-20"></div>
    <section id="section5" style="background: rgba(68, 10, 103, 0.08)">
      <TimeStudy />
    </section>

    <div class="h-16 lg-h-20"></div>
    <section id="section4" style="background: rgba(2, 89, 85, 0.08)">
      <Plexus />
    </section>

    <section id="section2" style="background: rgba(33, 26, 76, 0.08)">
      <Behold />
    </section>

    <div class="h-16 lg-h-20"></div>
    <section id="section3" style="background: rgba(143, 79, 79, 0.08)">
      <Oasis />
    </section>

    <Footer />
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Behold from "@/components/Behold";
import Oasis from "@/components/Oasis";
import Plexus from "@/components/Plexus";
import TimeStudy from "@/components/TimeStudy";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  data() {
    return {
      isActive: false,
      active: "home",
    };
  },
  components: { Footer, TimeStudy, Plexus, Oasis, Behold, Logo },
};
</script>

<style lang="scss" scoped>
.active {
  opacity: 1;
}
.inactive {
  opacity: 0.16;
}
</style>
