<template>
  <div
    class="flex flex-wrap lg:justify-between items-center pt-16 container-contents lg:pt-20 pb-16"
  >
    <div class="flex lg:flex-row flex-col justify-between items-center">
      <div class="order-2 lg:order-1 text-green lg:w-4/5">
        <h5
          class="text-center text-normal font-bold lg:font-semibold text-2xl leading-33 mb-4 lg:text-left mt-12"
        >
          Plexus
        </h5>
        <p
          class="w-full font-medium text-center lg:text-left text-base lg:text-xl tracking-widest1 leading-7 lg:leading-9 mb-8"
        >
          A web application that shows workforce representation of tech
          companies, along with diversity and inclusion commitments and
          practices.
        </p>
        <div class="flex justify-center lg:justify-start items-center mt-8">
          <div class="w-full text-center lg:text-left relative">
            <a
              href="/plexus"
              class="plexus-link-border outline-none focus:outline-none text-lg font-bold leading-25 tracking-widest1 text-green"
            >
              View the Case Study
            </a>
          </div>
        </div>
      </div>

      <a
        href="/plexus"
        class="w-full lg:2/5 order-1 lg:order-2 outline-none focus:outline-none"
      >
        <img
          class="object-cover object-center"
          src="../assets/images/plexus.png"
          alt="Screenshot of Plexus app"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Plexus",
};
</script>

<style lang="scss" scoped>
.plexus-link-border {
  position: relative;
  text-decoration: none;

  &::before {
    background: #025955;
    bottom: -4px;
    left: 0;
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
  }
  &:hover {
    &::before {
      animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
}

.plexus-link-border:hover:before {
  animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
