import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Test from "../views/Test.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/behold",
    name: "Behold",
    component: () => import("../views/Behold.vue"),
  },
  {
    path: "/oasis",
    name: "Oasis",
    component: () => import("../views/Oasis.vue"),
  },
  {
    path: "/plexus",
    name: "Plexus",
    component: () => import("../views/Plexus.vue"),
  },
  {
    path: "/timestudy",
    name: "TimeStudy",
    component: () => import("../views/TimeStudy.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
