<template>
  <section
    class="flex flex-wrap lg:justify-between items-center pt-16 container-contents lg:pt-20 pb-16 lg:pb-0"
  >
    <div
      class="flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-between"
    >
      <a
        href="/timestudy"
        class="w-9/12 h-auto outline-none focus:outline-none"
      >
        <img
          class="object-cover object-center"
          src="../assets/images/time-studyy.png"
          alt="Screenshot of Time Study app"
        />
      </a>

      <div class="lg:w-8/12 lg:pl-8">
        <h5
          class="text-purple text-center text-normal font-bold lg:font-semibold text-2xl leading-33 mb-4 lg:text-left lg:mt-12"
        >
          Time Study Now
        </h5>
        <p
          class="text-purple w-full font-medium text-center lg:text-left text-base lg:text-xl tracking-widest1 leading-7 lg:leading-9 mb-8"
        >
          A mobile app which allows healthcare practitioners to seamlessly track
          track and analyse how they spend their time, so that they can optimise
          their time and life.
        </p>

        <div class="flex justify-center lg:justify-start items-center mt-8">
          <div class="w-full text-center lg:text-left relative">
            <a
              href="/timestudy"
              class="timestudy-link-border outline-none focus:outline-none text-lg font-bold leading-25 tracking-widest1 text-purple"
            >
              View the Case Study
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TimeStudy",
};
</script>

<style lang="scss" scoped>
.timestudy-link-border {
  position: relative;
  text-decoration: none;

  &::before {
    background: #440a67;
    bottom: -4px;
    left: 0;
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
  }
  &:hover {
    &::before {
      animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
}

.timestudy-link-border:hover:before {
  animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
